import './bootstrap';
import Swiper from "swiper";
import {Autoplay, Grid, Navigation, Pagination} from "swiper/modules";
import Cookies from 'js-cookie'




$(document).ready(function () {
    $('[data-object="question"]')
        .each(function () {
            $(this).prop('open', 0)
        })
        .click(function () {
            if ($(this).prop('open')) {
                // close
                $(this).prop('open', 0)
                $(this).find('[data-object="icon"]').removeClass('rotate')
                $(this).find('[data-object="answer"]').hide()
            } else {
                // open
                $(this).prop('open', 1)
                $(this).find('[data-object="icon"]').addClass('rotate')
                $(this).find('[data-object="answer"]').show()
            }
        })


    let hash = window.location.hash
    if (hash) {
        hash = hash.slice(1)
        let parent = $('a[name="'+hash+'"]').parents('[data-object="question"]');
        $(parent).prop('open', 1)
        $(parent).find('[data-object="icon"]').addClass('rotate')
        $(parent).find('[data-object="answer"]').show()
    }



    function initSlider() {
        let count = $('[data-object="mainSlider"]').find('.slide').length

        if (count === 1) {
            $('.sliderNavigation').hide()
            return
        }

        if (count < 10) {
            count = '0'+count
        }
        $('[data-object="nextSlide total"]').html(count)
    }
    initSlider()

    var swiper = new Swiper(".heroSwiper", {
        loop: true,
        modules: [Navigation, Autoplay],
        spaceBetween: 0,
        centeredSlides: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        renderCustom: (swiper, current, total) => {
            $('[data-object="nextSlide total"]').html(total)
            return current + ' of ' + total;
        },
        navigation: {
            nextEl: ".heroSwiper-button-next",
            prevEl: ".heroSwiper-button-prev"
        },
        on: {
            autoplayTimeLeft(s, time, progress) {
                $('.heroSwiper-progress').css({
                    width: (100 - (progress * 100)) + '%'
                })
            }
        }
    });

    var swiperServices = new Swiper(".swiperServices", {
        modules: [Grid, Navigation],
        centeredSlides: false,
        grabCursor: false,

        slidesPerView: 1.2,
        spaceBetween: 25,

        navigation: {
            nextEl: '.swiperServices-button-next',
            prevEl: '.swiperServices-button-prev',
        },
        grid: {
            rows: 2,
        },

        breakpoints: {
            900: {
                slidesPerView: 2.5,
                grid: {
                    rows: 2,
                },
                spaceBetween: 25,
            },
            1200: {
                slidesPerView: 3,
                grid: {
                    rows: 2,
                    //column: 3,
                },
                spaceBetween: 25,
            },

        },
    });


    var swiperPartners = new Swiper(".swiperPartners", {
        modules: [Grid,Navigation],
        centeredSlides: false,
        grabCursor: false,

        slidesPerView: 2,
        spaceBetween: 35,

        navigation: {
            nextEl: '.swiperPartners-button-next',
            prevEl: '.swiperPartners-button-prev',
        },
        grid: {
            rows: 2,
        },
        breakpoints: {
            900: {
                slidesPerView: 3.5,
                grid: {
                    rows: 1,
                },
                spaceBetween: 37,
            },
            1200: {
                slidesPerView: 6,
                grid: {
                    rows: 1,
                },
                spaceBetween: 37,
            },

        },
    });

    var swiperCases = new Swiper(".swiperCases", {
        centeredSlides: false,
        grabCursor: false,
        /*pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },*/

        slidesPerView: 1.3,
        spaceBetween: 44,
        breakpoints: {
            900: {
                slidesPerView: 2.3,
                spaceBetween: 115,
            },
            1900: {
                slidesPerView: 3.3,
                spaceBetween: 115,
            },
        },
    });

    var swiperNews = new Swiper(".swiperNews", {
        slidesPerView: 1.5,
        spaceBetween: 20,

        //centeredSlides: false,
        //grabCursor: false,
        /*pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },*/
        breakpoints: {
            640: {
                slidesPerView: 2.5,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2.5,
                spaceBetween: 25,
            },
            1220: {
                slidesPerView: 4.3,
                spaceBetween: 28,
            },
        },
    });

    var swiperEmployee = new Swiper(".swiperEmployee", {
        modules: [Navigation],
        spaceBetween: 0,
        centeredSlides: true,
        autoHeight: true,
        navigation: {
            nextEl: '.swiperEmployee-button-next',
            prevEl: '.swiperEmployee-button-prev',
        },
        /*pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },*/
    });

    $('[data-object="vacancyCategory"]').each(function (index) {
        if (index === 0) {
            $(this).addClass('active')
            let id = $(this).data('id')
            setVisibleVacancy(id)
        }
    })

    $('[data-object="closeCookieBanner"]').click(function () {
        $('section.cookieSection').remove()
        Cookies.set('cookieBannerClose', 'true', { expires: 90 })
    })


    $('[data-object="onlyCloseCookieBanner"]').click(function () {
        $('section.cookieSection').remove()
    })


    $('[data-object="vacancyCategory"]').click(function () {
        $('[data-object="vacancyCategory"]').each(function () {
            $(this).removeClass('active')
        })
        $(this).addClass('active')
        let id = $(this).data('id')
        setVisibleVacancy(id)
    })

    function setVisibleVacancy(categoryId)
    {
        $('[data-object="vacancyItem"]').hide()
        $('[data-object="vacancyItem"][data-category="'+categoryId+'"]').show()
    }


    //setVisibleVacancy(id)


    var swiperVacancy = new Swiper(".swiperVacancy", {
        modules: [Navigation],
        spaceBetween: 0,
        //centeredSlides: true,
        //autoHeight: true,
        navigation: {
            prevEl: '.swiperVacancy-button-prev',
            nextEl: '.swiperVacancy-button-next',
        },
        /*pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },*/
    });




    /*const swiper = new Swiper('.swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,

        // If we need pagination
        /!*pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },*!/
    });*/



    $('[data-object="modalClose"]').click(function () {
        $(this).parents('[data-object="modal"]').removeClass('open')
    })

    $('[data-object="modalOpen"]').click(function () {
        let id = $(this).data('id')
        $('[data-object="modal"][data-id="'+id+'"]').addClass('open')

        if (id === 'companyRequest') {
            let typeId = $(this).data('company-type')
            let companyId = $(this).data('company-id')
            $('[data-object="modal"][data-id="'+id+'"] input[name="typeForm"]').val(typeId)
            $('[data-object="modal"][data-id="'+id+'"] input[name="companyId"]').val(companyId)
        }
    })



    $('[data-object="feedbackForm"]').on( "submit", function( event ) {
        event.preventDefault()

        $(this).find('button').addClass('load')

        // Сбрасываем ошибки
        $(this).find('.formBlock').each(function () {
            $(this).find('[data-object="input"]').removeClass('error')
            $(this).find('.formErrorMessage').html('')
            $(this).find('.errorMessage').html('')
        })

        // Собираем данные формы
        let form = {}
        $(this).find('[data-object="input"]').each(function () {
            form[$(this).prop('name')] = $(this).val() ?? null

            if ($(this).attr('type') === 'checkbox') {
                if ($(this).prop('checked')) {
                    form[$(this).prop('name')] = true
                } else {
                    form[$(this).prop('name')] = false
                }
            }
        })

        let url = `/${ window.lang ?? 'ru' }/feedback/send`
        axios
            .post(url, form)
            .then((data) => {
                let content = data.data

                if (content.success) {
                    $(this).find('input[type="text"][data-object="input"], textarea[data-object="input"]').each(function () {
                        $(this).val(null)
                    })
                    $('[data-object="modal"][data-id="successFeedback"]').addClass('open')
                }
            })
            .catch((data) => {
                if (data.response) {
                    if (data.response.status === 428) {
                        $(this).find('.formErrorMessage').html('Try again later')
                    }
                    if (data.response.status === 422) {
                        let content = data.response.data

                        for (let key in content.errors) {
                            $(this).find('.formBlock[data-id="'+key+'"]').find('[data-object="input"]').addClass('error')
                            $(this).find('.formBlock[data-id="'+key+'"]').find('.errorMessage').html(content.errors[key].join('. '))
                        }
                    }

                    //console.log(data.response.data);
                    //console.log(data.response.status);
                    //console.log(data.response.headers);
                } else if (data.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(data.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //console.log('Error', data.message);
                }
            })
            .finally(() => {
                $(this).find('button').removeClass('load')
            })
    });


    $('[data-object="feedbackModalForm"]').on("submit", function(event){
        event.preventDefault()

        $(this).find('button').addClass('load')

        // Сбрасываем ошибки
        $(this).find('.formBlock').each(function () {
            $(this).find('[data-object="input"]').removeClass('error')
            $(this).find('.errorMessage').html('')
        })

        // Собираем данные формы
        let form = {}
        $(this).find('[data-object="input"]').each(function () {
            form[$(this).prop('name')] = $(this).val() ?? null

            if ($(this).attr('type') === 'checkbox') {
                if ($(this).prop('checked')) {
                    form[$(this).prop('name')] = true
                } else {
                    form[$(this).prop('name')] = false
                }
            }
        })

        let url = `/${ window.lang ?? 'ru' }/feedback/send`
        axios
            .post(url, form)
            .then((data) => {
                let content = data.data

                if (content.success) {
                    $(this).find('input[type="text"][data-object="input"], textarea[data-object="input"]').each(function () {
                        $(this).val(null)
                    })
                    $('[data-object="modal"][data-id="successFeedback"]').addClass('open')
                }
            })
            .catch((data) => {
                if (data.response) {
                    if (data.response.status === 422) {
                        let content = data.response.data

                        for (let key in content.errors) {
                            $(this).find('.formBlock[data-id="'+key+'"]').find('[data-object="input"]').addClass('error')
                            $(this).find('.formBlock[data-id="'+key+'"]').find('.errorMessage').html(content.errors[key].join('. '))
                        }
                    }

                    //console.log(data.response.data);
                    //console.log(data.response.status);
                    //console.log(data.response.headers);
                } else if (data.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(data.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //console.log('Error', data.message);
                }
            })
            .finally(() => {
                $(this).find('button').removeClass('load')
            })
    });


    $('[data-object="companyModalForm"]').on("submit", function(event){
        event.preventDefault()

        $(this).find('button').addClass('load')

        // Сбрасываем ошибки
        $(this).find('.formBlock').each(function () {
            $(this).find('[data-object="input"]').removeClass('error')
            $(this).find('.errorMessage').html('')
        })

        // Собираем данные формы
        let form = {}
        $(this).find('[data-object="input"]').each(function () {
            form[$(this).prop('name')] = $(this).val() ?? null

            if ($(this).attr('type') === 'checkbox') {
                if ($(this).prop('checked')) {
                    form[$(this).prop('name')] = true
                } else {
                    form[$(this).prop('name')] = false
                }
            }
        })

        let url = `/${ window.lang ?? 'ru' }/company/send`
        axios
            .post(url, form)
            .then((data) => {
                let content = data.data

                if (content.success) {
                    $(this).find('input[type="text"][data-object="input"], textarea[data-object="input"]').each(function () {
                        $(this).val(null)
                    })
                    $('[data-object="modal"][data-id="successFeedback"]').addClass('open')
                }
            })
            .catch((data) => {
                if (data.response) {
                    if (data.response.status === 422) {
                        let content = data.response.data

                        for (let key in content.errors) {
                            $(this).find('.formBlock[data-id="'+key+'"]').find('[data-object="input"]').addClass('error')
                            $(this).find('.formBlock[data-id="'+key+'"]').find('.errorMessage').html(content.errors[key].join('. '))
                        }
                    }

                    //console.log(data.response.data);
                    //console.log(data.response.status);
                    //console.log(data.response.headers);
                } else if (data.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(data.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //console.log('Error', data.message);
                }
            })
            .finally(() => {
                $(this).find('button').removeClass('load')
            })
    });




    $('[data-object="sendCareerForm"] input[type="file"]').on('change', function () {
        let files = $(this).prop('files');
        if (files.length) {
            let file = files[0]
            //console.log(file)
            $(this).parents('.formBlock').find('.fileInfo').html(file.name)
        }

    })

    $('[data-object="sendCareerForm"]').on("submit", function( event ) {
        event.preventDefault()

        $(this).find('button').addClass('load')

        // Сбрасываем ошибки
        $(this).find('.formBlock').each(function () {
            $(this).find('[data-object="input"]').removeClass('error')
            $(this).find('.errorMessage').html('')
        })

        // Собираем данные формы
        let form = new FormData
        $(this).find('[data-object="input"]').each(function () {
            if ($(this).prop('type') === 'file') {
                let files = $(this).prop('files');
                if (files.length) {
                    let file = files[0]
                    form.append('uploadFile', file)
                }
            } else if ($(this).attr('type') === 'checkbox') {
                if ($(this).prop('checked')) {
                    form.append($(this).prop('name'), "1")
                } else {
                    form.append($(this).prop('name'), "0")
                }
            } else {
                form.append($(this).prop('name'), $(this).val() ?? null)
            }
        })

        //let url = `/${ window.lang ?? 'ru' }/career/send`
        let url = $(this).attr('action')
        axios
            .post(url, form)
            .then((data) => {
                let content = data.data

                if (content.success) {
                    $(this).find('input[type="file"][data-object="input"], input[type="text"][data-object="input"], textarea[data-object="input"]').each(function () {
                        $(this).val(null)

                        $(this).parents('.formBlock').find('.fileInfo').html('')
                    })
                    $('[data-object="modal"][data-id="successFeedback"]').addClass('open')
                }
            })
            .catch((data) => {
                if (data.response) {
                    if (data.response.status === 422) {
                        let content = data.response.data

                        for (let key in content.errors) {
                            $(this).find('.formBlock[data-id="'+key+'"]').find('[data-object="input"]').addClass('error')
                            $(this).find('.formBlock[data-id="'+key+'"]').find('.errorMessage').html(content.errors[key].join('. '))
                        }
                    }

                    //console.log(data.response.data);
                    //console.log(data.response.status);
                    //console.log(data.response.headers);
                } else if (data.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(data.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //console.log('Error', data.message);
                }
            })
            .finally(() => {
                $(this).find('button').removeClass('load')
            })
    });




    $('[data-object="closeSubMenu"]').on('click', function () {
        $('[data-object="openSubMenu"]').each(function() {
            $(this).removeClass('close')
        })
        $(this).parents('.navigation').find('.contacts').show()
        $(this).parents('.navigation').removeClass('hide')
        $(this).parents('.subMenu').removeClass('open')
    })



    $('[data-object="openSubChildMenu"]').on('click', function (event) {
        //event.preventDefault()

        if (!isMobile()) {
            return
        }

        let parent = $(this).parents('.item')

        if (parent.prop('open')) {
            parent.removeClass('open')
            parent.prop('open', 0)
        } else {
            event.preventDefault()
            parent.addClass('open')
            parent.prop('open', 1)
        }
    })

    function isMobile() {
        if ($(window).width() <= 1100) {
            return true
        }
        return false
    }

    $(document).click(function (e) {
        if ($(e.target).closest(".languages").length === 0) {
            $(".head .languages").removeClass('open').prop('open', 0);
        }
    });

    $('.head .languages')
        .on('click', function () {
            if ($(this).prop('open')) {
                $(this).removeClass('open').prop('open', 0)
            } else {
                $(this).addClass('open').prop('open', 1).focus()
            }
        })
        .on('focusout', function () {
            $(this).removeClass('open').prop('open', 0)
        })
        .on('blur', function () {
            $(this).removeClass('open').prop('open', 0)
        })

    $('[data-object="openSubMenu"]').on('click', function (event) {
        let length = Number($(this).attr('data-submenu-length'))

        if (!isMobile()) {
            return
        }

        if (length !== 0) {
            event.preventDefault()
            $('[data-object="openSubMenu"]').each(function() {
                $(this).addClass('close')
            })
            $(this).parents('.navigation').find('.contacts').hide()
            $(this).parents('.navigation').addClass('hide')
            $(this).parent().children('.subMenu').addClass('open')
        }

        if ($(this).attr('href') !== '#') {
            $(this).find('.parentLink').app
        }
    })

    $('[data-object="mobileMenuButton"]').on('click', function () {
        if ($(this).prop('open')) {
            $(this).removeClass('open')
            $(this).prop('open', 0)
            $('[data-object="mobileMenu"]').removeClass('open')
            $('body').removeAttr('style')
        } else {
            $(this).addClass('open')
            $('[data-object="mobileMenu"]').addClass('open')
            $(this).prop('open', 1)
            $('body').attr('style', 'position: fixed')
        }
    })

    $('[data-object="copyUrl"]').on('click', function () {
        let url = $(this).attr('data-url')
        copyToClipboard(url)
    })

    function copyToClipboard(url) {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(url).select();
        document.execCommand("copy");
        $temp.remove();
    }

})
